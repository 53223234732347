<template>
  <div class="pa-4">
    <v-btn color="primary" @click="$router.push(`/admissions/new`)"> Create </v-btn>
    <v-data-table :headers="headers" :items="listOfAdmissions" :items-per-page="5">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="primary" @click="$router.push(`/admissions/${item._id}`)"><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn icon color="error" @click="deleteAdmission(item)"><v-icon>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>

export default {
  data () {
    return {
      headers: [
        { text: 'Name', align: 'start', sortable: false, value: 'name', },
        { text: 'Course', value: 'course' },
        { text: 'Date', value: 'date' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions' },
      ],
      listOfAdmissions: [ ]
    }
  },
  mounted () {
    this.$_execute('get', 'admissions').then(response => {
      this.listOfAdmissions = response.data
    })
  },
  methods: {
    deleteAdmission () {
      // 
    }
  }
}
</script>

